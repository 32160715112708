#logo text{
  user-select: none;
  font-family:'Roboto Mono';
  font-size:14px;
}

#logo tspan {
  font-family:'Nunito', monospace;
  font-size: 14px;
}

#logo .star {
  fill:var(--color-y);
}

text .tspan-0 {
  fill:var(--color-p);
}

text .tspan-1 {
  fill:var(--color-g);
}


#logo .broken{
  stroke-dasharray:8;
}

#logo .filled{
  fill:black;
}


#logo .end_marked_arrow{
  marker-end:url(#arrow);
}

#logo .start_marked_arrow{
  marker-start:url(#arrow);
}

#logo .end_marked_diamond{
  marker-end:url(#diamond);
}

#logo .start_marked_diamond{
  marker-start:url(#diamond);
}

#logo .end_marked_circle{
  marker-end:url(#circle);
}

#logo .start_marked_circle{
  marker-start:url(#circle);
}

#logo .end_marked_open_circle{
  marker-end:url(#open_circle);
}

#logo .start_marked_open_circle{
  marker-start:url(#open_circle);
}

#logo .end_marked_big_open_circle{
  marker-end:url(#big_open_circle);
}

#logo .start_marked_big_open_circle{
  marker-start:url(#big_open_circle);
}

.fade {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}


.animate-spin-slow {
  animation: spin 10s linear infinite;
  transition: transform 0.3s ease-in-out;
}

#svgCont {
  height: 100%;
  width: 100%;
}

#svgCont:hover .animate-spin-slow {
  animation: spin-reverse 5s linear infinite;
  animation-fill-mode: both;
  transform: rotate(180deg);
  cursor: pointer;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin-reverse {
  100% {
    transform: rotate(-360deg);
  }
}
