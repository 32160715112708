/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #8f54a0 #000000;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 13px;
  position: fixed; /* Set position to fixed */
  z-index: 9999; /* Set a high z-index value to ensure it appears on top */
}

*::-webkit-scrollbar-track {
  background: #000000;
}

*::-webkit-scrollbar-thumb {
  background-color: #8f54a0;
  border-radius: 13px;
  border: 3px solid #000000;
}